import React from "react";

import Layout from "../components/global/layout";
import AboutHeader from "../components/about/AboutHeader";
import AboutContent from "../components/about/AboutContent";
import GetInTouchToday from "../components/global/GetInTouchToday";

import SEO from "../components/global/seo";
import {graphql, StaticQuery} from "gatsby";

export default () => (
    <StaticQuery
        query={graphql`
      query AboutPageQuery {
        allStrapiAboutpage {
          edges {
            node {
              MetaDescription
            }
          }
        }
      }
    `}
        render={data => (
            <Layout>
                {data.allStrapiAboutpage.edges.map(document => (
                    <SEO
                        title="About Us" keywords={[`discovery`, `journey`, `team`]}
                        description={document.node.MetaDescription}
                    />
                ))}
                <AboutHeader />
                <AboutContent />
                <GetInTouchToday />
            </Layout>
        )}
    />
);
