import React from "react";
import { StaticQuery, graphql } from "gatsby";
import "./AboutHeader.css";
import GenericHeader from "../templates/genericHeader";
import AboutImage from "../../assets/about/valor-digital-solutions-about-us-header.jpg";

export default () => (
  <StaticQuery
    query={graphql`
      query AboutHeadingQuery {
        allStrapiAboutpage {
          edges {
            node {
              PageHeading
              PageHeadingBlurb
            }
          }
        }
      }
    `}
    render={data => (
      <div className="tabarja-about">
        <GenericHeader
          Heading={data.allStrapiAboutpage.edges.map(document => (
            <div>{document.node.PageHeading}</div>
          ))}
          headerInfo={data.allStrapiAboutpage.edges.map(document => (
            <div>{document.node.PageHeadingBlurb}</div>
          ))}
          infoHeaderImage={AboutImage}
          infoHeaderImageAlt={"About Us Valor Digital Solutions"}
        />
      </div>
    )}
  />
);
