import React from "react";
import { StaticQuery, graphql } from "gatsby";
import "./AboutContent.css";
import BoxWidget from "../global/BoxWidget";
import AboutImage from "../../assets/about/about_image.jpg";
import ReactMarkdown from "react-markdown";

export default () => (
  <StaticQuery
    query={graphql`
      query AboutContentQuery {
        allStrapiAboutpage {
          edges {
            node {
              ContentHeadingOne
              ContentBodyOne
              ContentHeadingTwo
              ContentBodyTwo
              ContentHeadingThree
              ContentBodyThree
            }
          }
        }
      }
    `}
    render={data => (
      <div className="valor-about">
        <section>
          <div className="container-fluid">
            <div className="d-flex pt-5 pb-5">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 flex-grow-1 my-auto">
                <div className="pl pr-3 my-auto">
                  {data.allStrapiAboutpage.edges.map(document => (
                    <h3 className="sec-header-sub">
                      {document.node.ContentHeadingOne}
                    </h3>
                  ))}
                  <div>
                    {data.allStrapiAboutpage.edges.map(document => (
                      <p className="sub-para">
                        <ReactMarkdown source={document.node.ContentBodyOne} />
                      </p>
                    ))}
                  </div>
                </div>
              </div>
              <div className="my-auto">
                <div className="pl-5 pr">
                  <BoxWidget />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container-fluid pt-4 pb-5">
            <div className="d-flex">
              <div className="m-auto">
                <img src={AboutImage} alt="Who are we" width="100%" />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container-fluid pt-5 pb-5">
            <div className="row">
              <div className="col-md-6 col-sm-12 pb-5">
                <div className="pl pr-4">
                  
                {data.allStrapiAboutpage.edges.map(document => (
                    <h3 className="sec-header-sub">
                      {document.node.ContentHeadingTwo}
                    </h3>
                  ))}
                  
                  {data.allStrapiAboutpage.edges.map(document => (
                    <p className="sub-para">
                      <ReactMarkdown source={document.node.ContentBodyTwo} />
                    </p>
                  ))}
                  
                
                </div>
              </div>
              <div className="col-md-6 col-sm-12 pb-5">
                <div className="pl pr-4">
                  
                {data.allStrapiAboutpage.edges.map(document => (
                    <h3 className="sec-header-sub">
                      {document.node.ContentHeadingThree}
                    </h3>
                  ))}
                  
                  {data.allStrapiAboutpage.edges.map(document => (
                    <p className="sub-para">
                      <ReactMarkdown source={document.node.ContentBodyThree} />
                    </p>
                  ))}

                
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )}
  />
);
